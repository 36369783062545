import { ChainId } from '../../../../types/mod';

export const TAP_PROXY_ADDRESS = {
    [ChainId.BSCTestnet]: '0x2e6195eD946064aC6aAD70847788891F29AB62C9',
    [ChainId.BSC]: '0xF7d38F63aBFD6d27b293733a6553303Cf3933778',
    [ChainId.Aurora]: '0x93E94ef7D2d735fF21C302c765d8A77C1955A311',
    [ChainId.Arbitrum]: '0xe317266a34B9C35285d4050ad784d5190467F489',
    [ChainId.Matic]: '0x7d2aCf0F34958597910Db1D057bCb4908aC37Db1',
    [ChainId.MantleTest]: '0xB2B0cde11223ab8EE62573298afBF3506Ff31755',
    [ChainId.ScrollTestL2]: '0xffCE438BB30D468f78F12BB9d9e237B7D3Eb8bd8',
    [ChainId.ZkSyncAlphaTest]: '0x30Dd9d18CfC94AD34dD75defb71b189831d46688',
    // [ChainId.ZkSyncEra]: '0xe7d7b5cd36dEeD96C2aDe3Da228d9792D2BE756A',
    [ChainId.ZkSyncEra]: '0xaC9E38E8Ea126aF1Fa1822717C75b971BD69E8aa',
    [ChainId.Loot]: '0x01Cc44fc1246D17681B325926865cDB6242277A5',
    [ChainId.Base]: '0x113af14703b6639516459E01447024137F591aa1',
    
    [ChainId.Icplaza]: '0xFb18f95bf1C52c8B734486f808864FC19E572641',
    [ChainId.ConfluxESpace]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
    [ChainId.Meter]: '0x0E039b3c26560a19f7D6C153EcBf8F25435a69b6',
    [ChainId.Telos]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
} as Record<ChainId, string>;