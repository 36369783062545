import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';
import Web3 from 'web3';

export interface BlockState {
    currentBlock: number
    initialBlock: number
    showError:boolean
    remainSeconds: number
    lastUpdateTime: number
}

export const block = createModel<RootModel>()({
    state: {
        currentBlock: 0,
        initialBlock: 0,
        showError:false,
        lastUpdateTime: 0,
        remainSeconds:0,
    } as BlockState,
    reducers: {
        setBlockNumber: (state: BlockState, blockNumber: number) => produce(state, draft => {
            if (state.initialBlock === 0) {
                draft.initialBlock = blockNumber;
            }
            if (!(state.currentBlock === blockNumber)) {
                draft.lastUpdateTime = new Date().getTime();
            }
            draft.currentBlock = blockNumber;
        }),
        setShowError: (state: BlockState, isShow: boolean) => produce(state, draft => {
            draft.showError = isShow;
        }),
        setRemainSeconds: (state: BlockState, remainSeconds: number) => produce(state, draft => {
            draft.remainSeconds = remainSeconds;
        }),
    },
	effects: (dispatch) => ({
        async fetchBlockNumber(web3: Web3, rootState): Promise<number> {
         
            checkWeb3Connection(web3, rootState.block.lastUpdateTime)
            .then((isConnected) => {
              if (isConnected) {
                dispatch.block.setShowError(false);
              } else {
                dispatch.block.setShowError(true);
              }
            })
            .catch((error) => {
                dispatch.block.setShowError(true);
                console.info("Error checking web3 connection:", error);
            });
            const blockNumber = await web3.eth.getBlockNumber().catch((e: any)=>{
                console.info("error:   ", e.message)
                
            });
            if(blockNumber){
                dispatch.block.setBlockNumber(blockNumber);
                return blockNumber;
            }
            return 0  
        }
	})
});

async function checkWeb3Connection(web3: Web3, lastUpdateTime: number): Promise<boolean> {
    let flag1 = true;
    let flag2 =  true;
    try {
      const isListening = await web3.eth.net.isListening();
      flag1 = isListening;
    } catch (error) {
      flag1 = false;
    }
    try {
      const blockNumber = await web3.eth.getBlockNumber();
      flag2 = blockNumber > 0 && ((new Date().getTime() - lastUpdateTime < 60 * 1000) || lastUpdateTime === 0); //TODO: for each chain
    } catch (error) {
      flag2 = false;
    }
    return flag1 || flag2;
  }
  