import { ChainId, TokenSymbol } from '../types/mod';
import chains from './chains';
import { tokenSymbol2token } from './tokens';

export const DISABLE_CONSOLE = false;

export const TOKEN_CONFIG = {
    AUTO_REFRESH_TOKEN_PRICE_INTERVAL: 60 * 1000,
    INITIAL_FETCH_TOKEN_LIST: [
        TokenSymbol.USDC,
        TokenSymbol.ETH,
        TokenSymbol.WETH,
        TokenSymbol.USDT,
        TokenSymbol.IZI,
        TokenSymbol.BUSD,
        TokenSymbol.WBNB,
        TokenSymbol.IUSD,
        TokenSymbol.WBNB,
        TokenSymbol.BNB,
    ],
};

export const DEFAULT_CHAIN_ID = ChainId.Base;
export const DEFAULT_CHAIN_ID_TEST = ChainId.Base;

export const FARM_CONFIG = {
    AUTO_REFRESH_FARM_DATA_INTERVAL: 6 * 1000,
    REWARD_DISPLAY: 'day', // day or block/second
    APR_FEE_GAINS: {
        [ChainId.EthereumMainnet]: 2,
        [ChainId.Matic]: 6,
        [ChainId.Optimism]: 0,
        [ChainId.BSC]: 0,
        [ChainId.Gatechain]: 0,
        [ChainId.Arbitrum]: 3,
        [ChainId.Rinkeby]: 0,
        [ChainId.Aurora]: 0,
        [ChainId.ETC]: 0,
    } as Record<ChainId, number>,
    ALL_FACTOR: 0.99999,
};

export const UNISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZISWAP_MINT_CONFIG = {
    AUTO_REFRESH_POOL_STATE_INTERVAL: 15 * 1000,
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZUMI_SWAP_CONFIG = {
    AUTO_REFRESH_LIQUIDITY_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_SWAP_CALC_INTERVAL: 15 * 1000,

    ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT: 4,

    SWAP_DEFAULT_SLIPPAGE_PERCENT: 0.5,
    TAP_SWAP_DESIRED_MIN_AMOUNT_FACTOR: 0.995,
    SWAP_DEFAULT_MAXIMUM_DELAY: 10,
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.985,

    DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR: 0.85,

    SWAP_PRICE_FEE_SWITCH: false, // if true, displayed swap price (current price) will count in fee cost
};

export const ACCOUNT_CONFIG = {
    AUTO_REFRESH_TOKEN_BALANCE_INTERVAL: 15 * 1000,
};

export const CHAIN_CONFIG = {
    AUTO_REFRESH_GAS_PRICE_INTERVAL: 10 * 1000,
    AUTO_REFRESH_BLOCK_NUMBER_INTERVAL: 5 * 1000,
};

export const PRO_CONFIG = {
    KLINE_AUTO_REFRESH_INTERVAL: 15 * 1000,
    ORDERBOOK_AUTO_REFRESH_PRICE_INTERVAL: 3 * 1000,
    POOL_STATE_REFRESH_INTERVAL: 15 * 1000,
};

export const PRO_DEFAULT_MARKET = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const markets = {
        [ChainId.Base]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDC, ChainId.Base),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.Base),
            pool: '0x1346C3A242B8325F032365044293693f2EF0F69A', //2023.08.01
            fee: 3000,
            market: 'WETH_USDC_500',
        },
    } as any;
    memo[chain.id] = markets[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const AUTO_LOGIN = true;

export const A_LONG_FUTURE_TIME = new Date('2026-01-01').getTime() / 1000;

export const FAKE_PRICE = {
    use: false,
    price: {
        USDC: 0.999999,
        iUSD: 0.9999,
        BNB: 255.98,
        USDT: 1,
        iZi: 0.029,
        BUSD: 1,
    },
} as any;

export const HOT_TOKENS = {
    [ChainId.BSC]: ['BNB', 'iZi', 'iUSD'],
    [ChainId.Aurora]: ['iZi', 'iUSD', 'ARC', 'USN'],
    [ChainId.BSCTestnet]: ['iZi', 'BNB'],
    [ChainId.Base]: ['ETH', 'WETH', 'USDC', 'USDbc', 'Rebase', 'SOFI', 'DAI', 'SEAM', 'WAMPL'],
    [ChainId.Loot]: ['AGLD', 'WAGLD', 'DAI'],
} as any;

export const SEARCH_TOKENS_PRO = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.ZkSyncEra]: ['USDC', 'WETH'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const CUSTOM_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0x76E4EFB2c78b08c3fdE3eDF98c6503dd9Df9C5E8', //ANT
            '0xa0D289c752FE23043f29BD81e30D8345180bD9BB', //WrappedANT,
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_FROM_TOKEN = chains.all.reduce<Record<number, string | string[]>>((memo, chain) => {
    const tokens = {} as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_TO_TOKEN = chains.all.reduce<Record<number, string | string[]>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: 'USDT', //iZi
        [ChainId.ZkSyncEra]: 'USDC',
        [ChainId.Loot]: 'DAI',
        [ChainId.Base]: 'USDC',

        //[ChainId.LineaTest]: ['USDT', '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const HIGHTLIGHT_POOLS = {
    [ChainId.ZkSyncEra]: [
        '0x6ac81d4c43c86c8dbd4842c1eb0fd1a1c2c16b97',
        '0x74e4D5Fdb20085AaaBcABc4f0dbe9a7d85C8e1c9',
        '0x7aa33c576954e86460a94a2fa1ff7f7d3403e7e3',
    ],
} as any;

export enum RiskEnum {
    High = 'High',
    Low = 'Low',
    Medium = 'Medium',
}

export const POOLS_APR_RANGE = {
    [ChainId.ZkSyncEra]: [
        {
            pool_address: '0x43ff8a10b6678462265b00286796e88f03c8839a',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.2%-[-20%,+20%]
        {
            pool_address: '0x2158ec5366effca1216af93ee0d77762e2ebd466',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.04%-[-20%,+20%]
        {
            pool_address: '0xe930f025edac0fe0dc8fe343d18a355d0b68aff6',
            leftRange: -2,
            rightRange: 2,
        }, // BUSD-USDC-0.04%-[-2%,+2%]
        // {
        //     pool_address: '0x667f19b8db045db612c1ecb371bcfef9ac3a401e',
        //     leftRange: -2,
        //     rightRange: 2,
        // }, // slUSDT-USDC-0.04%-[-2%,+2%]
    ],
} as any;

export const POPULAR_LP_SELECTION = {
    // pool_address, range_left, range_right, risk
    // mainnet
    [ChainId.ZkSyncEra]: [
        {
            pool_address: '0x43ff8a10b6678462265b00286796e88f03c8839a',
            leftRange: -2,
            rightRange: 2,
            risk: RiskEnum.High,
        }, // WETH-USDC-0.2%-[-2%,+2%]
        {
            pool_address: '0x43ff8a10b6678462265b00286796e88f03c8839a',
            leftRange: -10,
            rightRange: 10,
            risk: RiskEnum.High,
        }, // WETH-USDC-0.2%-[-10%,+10%]
        {
            pool_address: '0x43ff8a10b6678462265b00286796e88f03c8839a',
            leftRange: -20,
            rightRange: 20,
            risk: RiskEnum.Medium,
        }, // WETH-USDC-0.2%-[-20%,+20%]
        {
            pool_address: '0x43ff8a10b6678462265b00286796e88f03c8839a',
            leftRange: -50,
            rightRange: 50,
            risk: RiskEnum.Low,
        }, // WETH-USDC-0.2%-[-50%,+50%] (if true use eth)
    ],
    // testnet
    [ChainId.ZkSyncAlphaTest]: [
        {
            pool_address: '0x8B8681130877E047F7B535b17201D2FE90dF820b',
            leftRange: -2,
            rightRange: 2,
            risk: RiskEnum.High,
        },
        {
            pool_address: '0x8B8681130877E047F7B535b17201D2FE90dF820b',
            leftRange: -50,
            rightRange: 50,
            risk: RiskEnum.Low,
        },
    ],
} as any;

export const SHOW_REFRESH_PAGE = ['/swap', '/liquidity'];

export const FEE_CHARGE_PERCENT = 20;

export const LIMIT_ORDER_DISABLE_CHAIN = [] as ChainId[]; //ChainId.EthereumMainnet
