import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import MediaButton from '../../../iZUMi-UI-toolkit/src/components/Sidebar/MediaButton/MediaButton';
import TelegramIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/TelegramIcon/TelegramIcon';
import TwitterIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/TwitterIcon/TwitterIcon';
import MediumIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/MediumIcon/MediumIcon';
import DiscordIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/DiscordIcon/DiscordIcon';
import RedditIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/RedditIcon/RedditIcon';
import DefipulseIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/DefipulseIcon/Defipulse';
import { links } from '../../../config/links';

const MediaList: React.FC = () => {
    return (
        <Flex direction="row" p="0" mb="17px" alignSelf={{ base: 'start', sm: 'center' }}>
            <Box mr="8px">
                <MediaButton Icon={TelegramIcon} link={links.telegram} />
            </Box>
            <Box mr="8px">
                <MediaButton Icon={TwitterIcon} link={links.twitter} />
            </Box>
            <Box mr="8px">
                <MediaButton Icon={MediumIcon} link={links.medium} />
            </Box>
        </Flex>
    );
};

export default MediaList;
