import { VStack, Image, Divider, useDisclosure, useColorMode } from '@chakra-ui/react';
import { loginWithParticle, particle } from '../../utils/particle';
import useAuth from '../../hooks/useAuth';
import Card from '../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Heading, Text } from '../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import Connector from '../../iZUMi-UI-toolkit/src/components/Wallet/Connector';
import { ConnectorNames, connectorsArray } from '../../utils/connectors';
import { getColorThemeSelector } from '../../utils/funcs';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { RootDispatch } from '../../state/store';

const ConnectorList: React.FC = () => {
    const { onClose } = useDisclosure();
    const { login } = useAuth();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    return (
        <VStack as={Card} p="34px 16px" spacing="10px" w="266px" maxH="100%" bg={colorTheme('undefined', '#34294A')} overflow="scroll">
            <Heading level="4" mb="28px!important">
                Connect To A Wallet
            </Heading>
            {connectorsArray.map(({ name, displayName, url }, i) => {
                if (name == ConnectorNames.Particle) { return }
                return (
                    <Connector
                        key={i}
                        handleClick={() => {
                            console.info('name:  ', name);
                            onClose();
                            // if (name == ConnectorNames.Particle) {
                            //     loginWithParticle('email');
                            //     dispatch.loginConfig.setLoginConfig(ConnectorNames.Particle);
                            // } else {
                                login(name);
                                dispatch.loginConfig.setLoginConfig(name);
                            // }
                        }}
                        name={displayName}
                        icon={<Image src={process.env.PUBLIC_URL + '/assets/wallet/' + url} w="49px" h="47px" borderRadius="6px" />}
                    />
                );
            })}
            <Divider mt="42px!important" />
            <Text variant="display" fontSize="10px" color="tertiary.400" lineHeight="12px" fontWeight="400" mt="18px!important">
                By connecting a wallet, you agree to iZUMi Terms of Service.
            </Text>
        </VStack>
    );
};

export default ConnectorList;
