import { Box, BoxProps, HStack, VStack, useColorMode } from '@chakra-ui/react';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { formatNumber } from '../../../utils/tokenMath';
import React, { useMemo } from 'react';
import { i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';

type Props = {
    tokenId?: string;
    earned: number[];
    entry: any;
    onHarvest?(): void;
    havestBtStyle?: BoxProps;
} & BoxProps;

export const Earned: React.FC<Props> = ({ tokenId, earned, onHarvest, entry, havestBtStyle, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    const hasReward = useMemo(() => {
        let flag = false;
        Array.isArray(earned) &&
            earned.forEach((e) => {
                if (!isNaN(Number(e)) && Number(e) > 0) {
                    flag = true;
                }
            });
        return flag;
    }, [earned]);

    const gainItem = (i: number, item: any) => {
        return (
            <HStack w="100%" key={i} spacing="4px" alignItems="center" pl="4px !important">
                <Box w="16px" h="16px" borderRadius="8px" bgImage={entry.data.reward?.[i]?.[0].icon} bgSize="cover"></Box>

                <Text
                    variant="caption-bold"
                    fontFamily={{ base: 'Graphik', sm: 'Graphik' }}
                    color={colorTheme('tertiary.700', 'tertiary.50')}
                >
                    {isNaN(item) ? 0 : formatNumber(item / 10 ** entry.data.reward?.[i]?.[0].decimal ?? 18, 2, 2, true)}
                </Text>
            </HStack>
        );
    };

    return (
        <HStack
            justifyContent="space-between"
            alignItems="center"
            bg={colorTheme('#F9F9FB', '#302543')}
            p={{ base: '12px 15px 8px 0px', sm: '12px 15px 14px 10px' }}
            spacing="10px"
            flexShrink={0}
            w={{ base: '100%', sm: '232px', xlp1: '245px' }}
            {...rest}
        >
            {Array.isArray(earned) && earned.length > 1 ? (
                <HStack spacing="6px" alignItems="start" justifyContent="start">
                    <Text variant="caption" color={{ base: colorTheme('tertiary.500', '#A398BD'), sm: 'tertiary.500' }}>
                        {t('Earned')}
                    </Text>
                    <VStack w="84px" spacing="7px" justifyContent="start">
                        {earned.map((item, i) => {
                            return gainItem(i, item);
                        })}
                    </VStack>
                </HStack>
            ) : (
                <VStack spacing="6px" flexShrink={0} alignItems="start">
                    <Text variant="caption" color="tertiary.500">
                        {t('Earned')}
                    </Text>
                    <HStack spacing="6px" w={{ base: '126px', xlp1: '132px' }}>
                        {Array.isArray(earned) &&
                            earned.map((item, i) => {
                                return gainItem(i, item);
                            })}
                    </HStack>
                </VStack>
            )}
            <CustomButton
                onClick={onHarvest}
                flexShrink={0}
                text={tokenId ? t('Harvest') : t('Harvest')} //TODO
                disabled={tokenId && !hasReward ? true : false}
                variant="primary2"
                fontClass={i_text_copy_bold}
                w={{ base: '100px', sm: '72px' }}
                h={{ base: '30px', sm: '40px' }}
                {...havestBtStyle}
            />
        </HStack>
    );
};
