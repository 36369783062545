import React, { useEffect } from 'react';
import { Stack, useColorMode } from '@chakra-ui/react';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import PageLayout from '../../../../components/layout/PageLayout';
import ChainNotValidBlockWrapper from '../../../components/ChainNotValidBlock';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import BackButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';
import { Heading } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import AddPopularLiquidity from './Popular/AddPopularLiquidity';
import { getColorThemeSelector } from '../../../../utils/funcs';

const AddLiquidity: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%" mt="20px">
                            <BackButton flex={20} />
                            <Stack flex={80} alignItems="center" mt="14px">
                                <Heading
                                    level={'4'}
                                    fontFamily="Graphik"
                                    fontSize="14px"
                                    fontWeight="600"
                                    color={colorTheme('#24193C', '#FBFBFB')}
                                >
                                    {t('Add Liquidity')}
                                </Heading>
                            </Stack>

                            <AddPopularLiquidity></AddPopularLiquidity>
                        </Stack>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('Add Liquidity')}
                    subtitle={t('Add a new position to earn transaction fees.')}
                    variant="secondary"
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%">
                            {/* <Box h="30px" /> */}
                            <AddPopularLiquidity></AddPopularLiquidity>
                            {/* <AddLiquidityForm /> */}
                        </Stack>
                    }
                    app="trade"
                />
            }
            internalWidth={{ base: '700px', xl: '1150px' }}
        />
    );
};

export default AddLiquidity;
