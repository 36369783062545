import React from 'react';
import ReactDOM from 'react-dom';
import { ColorModeScript } from '@chakra-ui/react';
import { HashRouter } from 'react-router-dom';

import './i18n';
import './index.css';

import App from './App';
import Providers from './providers/Providers';
import reportWebVitals from './reportWebVitals';
import theme from './theme/theme';

ReactDOM.render(
    <React.StrictMode>
        <Providers>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <HashRouter>
                <App />
            </HashRouter>
        </Providers>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
