import { Flex, VStack, Text } from '@chakra-ui/layout';
import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import Tabs from '../components/Tabs';
import SwapForm from './SwapForm';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { SwapDetail } from './components/SwapDetail';
import LimitForm from '../Limit/LimitForm';
import { i_text_d } from '../../../style';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { parallelCollect } from '../../../net/contractCall/parallel';
import { useTranslation } from 'react-i18next';
import { CandlestickData, IChartApi, ISeriesApi, Time } from 'lightweight-charts';
import { useBreakpointValue } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useColorMode } from '@chakra-ui/react';
import AdvanceChart from './AdvanceSwap/AdvanceChart';
import { iZiSwapKLinesRecordEnum, getIziSwapKLinesRecord } from '../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';

export type AdvanceSwapProps = {
    tabValue: any;
};

const HintText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <VStack h="100%" justifyContent="center">
            <Text className={i_text_d} variant="caption" lineHeight="24px" fontSize="16px">
                {text}
            </Text>
        </VStack>
    );
};

const AdvanceSwap: React.FC<AdvanceSwapProps> = (pros) => {
    const tabValue = pros.tabValue;
    const history = useHistory();
    const { t } = useTranslation();
    const chartRef = useRef<IChartApi>(null);
    const candlesSeries = useRef<ISeriesApi<'Candlestick'>>(null);
    const [candlestickData, setCandlestickData] = useState([] as CandlestickData[]);
    const {
        tradeSwap: { swapForm, isSearchingX, isSearchingY },
    } = useSelector((state: RootState) => state);
    const isXlp1 = useBreakpointValue({ base: false, xxl: false, xlp1: true, '2xl': true });
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const chartTheme = colorTheme('#ffffff', '#34294A');

    const [selectedInterval, setSelectedInterval] = useState('1h');
    const [chartLoading, setChartLoading] = useState(false);

    const { chainId } = useWeb3WithDefault();

    const validChart = swapForm.tokenX.address && swapForm.tokenY.address;
    useEffect(() => {
        if (!validChart) {
            setCandlestickData([]);
            return;
        }

        const dayMode = selectedInterval === iZiSwapKLinesRecordEnum.DAY;
        const page_size = 300;
        const fetchTokenPrice = (address: string) =>
            getIziSwapKLinesRecord({
                chain_id: chainId,
                interval: dayMode ? iZiSwapKLinesRecordEnum.DAY : iZiSwapKLinesRecordEnum.HOUR_1,
                identity: address,
                order_by: '-time',
                page_size: page_size,
            });
        setChartLoading(true);
        parallelCollect(fetchTokenPrice(swapForm.tokenX.address), fetchTokenPrice(swapForm.tokenY.address)).then(
            ([priceHistoryX, priceHistoryY]) => {
                const priceX = priceHistoryX.data.is_success ? priceHistoryX.data.data : [];
                const priceY = priceHistoryY.data.is_success ? priceHistoryY.data.data : [];

                const togglePrice = swapForm.tokenX.address.toUpperCase() > swapForm.tokenY.address.toUpperCase();
                const timeToPriceY = Object.fromEntries(priceY.map((p) => [p.timestamp, p.close]));
                const timeToPriceYOpen = Object.fromEntries(priceY.map((p) => [p.timestamp, p.open]));
                const timeToPriceYHigh = Object.fromEntries(priceY.map((p) => [p.timestamp, p.high]));
                const timeToPriceYLow = Object.fromEntries(priceY.map((p) => [p.timestamp, p.low]));

                const priceHist: number[][] = [];
                const priceCandlestickData: CandlestickData[] = [];
                for (let index = 0; index < priceX.length; index++) {
                    const matchPriceY = timeToPriceY[priceX[index].timestamp];
                    const matchPriceYOpen = timeToPriceYOpen[priceX[index].timestamp];
                    const matchPriceYHigh = timeToPriceYHigh[priceX[index].timestamp];
                    const matchPriceYLow = timeToPriceYLow[priceX[index].timestamp];

                    const currentPriceX = priceX[index].close;
                    const currentPriceXOpen = priceX[index].open;
                    const currentPriceXHigh = priceX[index].high;
                    const currentPriceXLow = priceX[index].low;
                    if (!matchPriceY || !currentPriceX) {
                        continue;
                    }
                    const price = Number(currentPriceX) / Number(matchPriceY);
                    const priceOpen = Number(currentPriceXOpen) / Number(matchPriceYOpen);
                    const priceHigh = Number(currentPriceXHigh) / Number(matchPriceYHigh);
                    const priceLow = Number(currentPriceXLow) / Number(matchPriceYLow);
                    priceHist.push([priceX[index].timestamp * 1000, togglePrice ? 1 / price : price]);

                    priceCandlestickData.push({
                        time: priceX[index].timestamp as Time,
                        open: togglePrice ? 1 / priceOpen : priceOpen,
                        high: togglePrice ? 1 / priceLow : priceHigh,
                        low: togglePrice ? 1 / priceHigh : priceLow,
                        close: togglePrice ? 1 / price : price,
                    });
                }
                setCandlestickData(priceCandlestickData.sort((a, b) => Number(a.time) - Number(b.time)));
                setChartLoading(false);
            }
        );
    }, [chainId, swapForm.tokenX.address, swapForm.tokenY.address, selectedInterval, validChart]);

    const handleChangeTab = (value: string) => {
        if (value === 'Swap') {
            history.push('/swap', { mode: 'advance' });
        }
        if (value === 'Limit Order') {
            history.push('/limit', { mode: 'advance' });
        }
    };

    return (
        <Flex w="1155px">
            <Flex pt="10px">
                <VStack>
                    {!validChart ? (
                        <Card w={{ base: '720px', xlp1: '770px' }} h="464px">
                            <HintText text="No related Chart" />
                        </Card>
                    ) : (
                        <AdvanceChart
                            chartWidth={isXlp1 ? 750 : 700}
                            height={464}
                            chartRef={chartRef}
                            series={candlesSeries}
                            data={candlestickData}
                            themeColor={chartTheme}
                            tokenA={swapForm.tokenX}
                            tokenB={swapForm.tokenY}
                            selectedInterval={selectedInterval}
                            setSelectedInterval={setSelectedInterval}
                            showInterval={[iZiSwapKLinesRecordEnum.HOUR_1, iZiSwapKLinesRecordEnum.DAY]}
                            chartLoading={chartLoading}
                        ></AdvanceChart>
                    )}
                    <Card w="100%" h="165px" px="29px" py="23px">
                        {swapForm.swapPath && !swapForm.noSufficientLiquidity ? (
                            <SwapDetail swapForm={swapForm} ready={!isSearchingX && !isSearchingY}></SwapDetail>
                        ) : (
                            <HintText text={t('Please select tokens and input amount to continue') + '.'} />
                        )}
                    </Card>
                </VStack>
                <Tabs
                    pl="15px"
                    list={[
                        {
                            label: t('Swap'),
                            value: 'Swap',
                            component: <SwapForm />,
                        },
                        {
                            label: t('Limit Order'),
                            value: 'Limit Order',
                            component: <LimitForm />,
                        },
                    ]}
                    value={tabValue}
                    handleClick={handleChangeTab}
                />
            </Flex>
        </Flex>
    );
};

export default AdvanceSwap;
