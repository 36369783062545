import React from 'react';
import { Box, HStack, Tooltip, VStack, Image } from '@chakra-ui/react';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { FARM_CONFIG } from '../../../config/bizConfig';
import { rewardDisplay } from '../../../utils/funcs';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { formatNumber } from '../../../utils/tokenMath';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { getScanUrl } from '../../../config/chains';
import { useTranslation } from 'react-i18next';
import { AdaptationMode } from '../../../components/layout/PageLayout';

export const Rewards: React.FC<{
    rewards: [TokenInfoFormatted, number][];
    oneLineMode: boolean;
    type?: AdaptationMode;
    useTimestamp?: boolean;
}> = ({ rewards, oneLineMode, type, useTimestamp }) => {
    const { t } = useTranslation();
    const { chainId } = useWeb3WithDefault();
    const rewardsValid = rewards.filter((e) => {
        return Number(e[1]) > 0;
    });

    const useTs = useTimestamp ?? false;

    //console.log('rewards: ', rewards);

    return type === 'mobile' ? (
        <VStack w="100%" direction={oneLineMode ? 'column' : 'row'} alignItems="start" px="30px">
            <Text variant={'caption'} color="#A398BD">
                {t('Rewards/day')}
            </Text>
            <HStack spacing="28px">
                {rewardsValid.map((reward, i) => (
                    <HStack key={i} w="140px">
                        <Image boxSize="16px" src={reward[0].icon}></Image>
                        <Text
                            variant={'caption'}
                            fontFamily="Graphik"
                            cursor="pointer"
                            onClick={() => {
                                window.open(getScanUrl(chainId) + 'token/' + reward[0].address);
                            }}
                        >
                            {formatNumber(rewardDisplay(Number(reward[1]), chainId, useTs), 0, 2) + ' ' + reward[0].symbol}
                        </Text>
                    </HStack>
                ))}
            </HStack>
        </VStack>
    ) : (
        <Info
            w="139px"
            direction={oneLineMode ? 'column' : 'row'}
            label={t('Rewards')}
            value={
                <HStack spacing="6px">
                    {rewardsValid.map((reward, i) => (
                        <Tooltip
                            label={
                                formatNumber(rewardDisplay(Number(reward[1]), chainId, useTs), 0, 2) +
                                ' ' +
                                reward[0].symbol +
                                '/' +
                                FARM_CONFIG.REWARD_DISPLAY
                            }
                            key={i}
                        >
                            <Box
                                w="16px"
                                h="16px"
                                borderRadius="8px"
                                flexShrink={0}
                                bgImage={reward[0].icon}
                                bgSize="cover"
                                cursor="pointer"
                                onClick={() => {
                                    window.open(getScanUrl(chainId) + 'token/' + reward[0].address);
                                }}
                            ></Box>
                        </Tooltip>
                    ))}
                    <Text variant="caption" ml="10px!important" whiteSpace="nowrap">
                        {rewardsValid.length === 1
                            ? formatNumber(rewardDisplay(Number(rewardsValid[0][1]), chainId, useTs), 0, 2) +
                              ' ' +
                              rewardsValid[0][0].symbol +
                              '/' +
                              FARM_CONFIG.REWARD_DISPLAY
                            : `${rewardsValid.length}  ` + t('rewards')}
                    </Text>
                </HStack>
            }
        />
    );
};
