import { Tabs, TabList, Tab, TabPanels, TabPanel, Text, HStack, Image, Stack, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../../../state/store';
import { noto_t3, noto_t3_bold } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import CurrentOrders from './components/CurrentOrders';
import PastOrders from './components/PastOrders';
type OrdersProps = {
    themeColor: any;
};
const Orders: React.FC<OrdersProps> = (props) => {
    const { themeColor } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { pro } = useSelector((state: RootState) => state);

    const [currentType, setCurrentType] = useState('CurrentOrders');
    const [isShowAllPairs, setIsShowAllPairs] = useState(false);
    const isMobile = useIsMobile();

    return isMobile ? (
        <Tabs h={pro.isShowAllOrders ? '564px' : ''} px="5px" bg={colorTheme('#FFFFFF', '#171424')} borderRadius="6px">
            <TabList justifyContent="5px" pl="24px" pr="14px" px="0px">
                <Tab
                    w="100px"
                    color="#A3A0A9"
                    px="2px"
                    _selected={{ color: '#5542F6', borderColor: '#5542F6', borderBottomWidth: '3px' }}
                    _hover={{
                        color: '#5542F6',
                    }}
                    onClick={() => {
                        setCurrentType('CurrentOrders');
                    }}
                >
                    <Text className={currentType === 'CurrentOrders' ? noto_t3_bold : noto_t3}>{t('Current Orders')}</Text>
                </Tab>
                <Tab
                    w="100px"
                    px="2px"
                    color="#A3A0A9"
                    ml="5px"
                    _selected={{ color: '#5542F6', borderColor: '#5542F6', borderBottomWidth: '3px' }}
                    _hover={{
                        color: '#5542F6',
                    }}
                    onClick={() => {
                        setCurrentType('PastOrders');
                    }}
                >
                    <Text className={currentType === 'PastOrders' ? noto_t3_bold : noto_t3}>{t('Past Orders')}</Text>
                </Tab>
                <HStack ml="auto" spacing="13px">
                    <HStack
                        cursor="pointer"
                        onClick={() => {
                            setIsShowAllPairs(!isShowAllPairs);
                        }}
                    >
                        <Text className={noto_t3} color="#5542F6">
                            {t('Show all')}
                        </Text>
                        <Stack
                            borderRadius="2px"
                            _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                        >
                            <Image
                                boxSize="16px"
                                src={isShowAllPairs ? '/assets/pro/orders/checked.svg' : '/assets/pro/orders/unCheck.svg'}
                                fallbackSrc="/assets/pro/orders/unCheck.svg"
                            ></Image>
                        </Stack>
                    </HStack>

                    <Stack
                        p="3px"
                        borderRadius="2px"
                        cursor="pointer"
                        _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                        onClick={() => {
                            dispatch.pro.setIsShowAllOrders(!pro.isShowAllOrders);
                        }}
                    >
                        <Image
                            boxSize="11px"
                            src={pro.isShowAllOrders ? '/assets/pro/closeFullScreen.svg' : '/assets/pro/fullScreen.svg'}
                        ></Image>
                    </Stack>
                </HStack>
            </TabList>

            <TabPanels>
                <TabPanel
                    h={pro.isShowAllOrders ? '529px' : 'auto'}
                    maxH={pro.isShowAllOrders ? '529px' : '450px'}
                    overflow={'scroll'}
                    py="0px"
                    px="0px"
                >
                    <CurrentOrders themeColor={themeColor}></CurrentOrders>
                </TabPanel>
                <TabPanel
                    h={pro.isShowAllOrders ? '529px' : 'auto'}
                    maxH={pro.isShowAllOrders ? '529px' : '450px'}
                    overflow={'scroll'}
                    py="0px"
                    px="0px"
                >
                    <PastOrders themeColor={themeColor}></PastOrders>
                </TabPanel>
            </TabPanels>
        </Tabs>
    ) : (
        <Tabs h={pro.isShowAllOrders ? '564px' : '205px'} bg={themeColor} borderRadius="6px">
            <TabList justifyContent="5px" pl="24px" pr="14px" mx="16px" px="0px">
                <Tab
                    w="100px"
                    color="#A3A0A9"
                    px="2px"
                    _selected={{ color: '#5542F6', borderColor: '#5542F6', borderBottomWidth: '3px' }}
                    _hover={{
                        color: '#5542F6',
                    }}
                    onClick={() => {
                        setCurrentType('CurrentOrders');
                    }}
                >
                    <Text className={currentType === 'CurrentOrders' ? noto_t3_bold : noto_t3}>{t('Current Orders')}</Text>
                </Tab>
                <Tab
                    w="100px"
                    px="2px"
                    color="#A3A0A9"
                    ml="5px"
                    _selected={{ color: '#5542F6', borderColor: '#5542F6', borderBottomWidth: '3px' }}
                    _hover={{
                        color: '#5542F6',
                    }}
                    onClick={() => {
                        setCurrentType('PastOrders');
                    }}
                >
                    <Text className={currentType === 'PastOrders' ? noto_t3_bold : noto_t3}>{t('Past Orders')}</Text>
                </Tab>
                <HStack ml="auto" spacing="13px">
                    <HStack
                        cursor="pointer"
                        onClick={() => {
                            setIsShowAllPairs(!isShowAllPairs);
                        }}
                    >
                        <Text className={noto_t3} color="#5542F6">
                            {t('Show all trading pairs')}
                        </Text>
                        <Stack
                            borderRadius="2px"
                            _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                        >
                            <Image
                                boxSize="16px"
                                src={isShowAllPairs ? '/assets/pro/orders/checked.svg' : '/assets/pro/orders/unCheck.svg'}
                                fallbackSrc="/assets/pro/orders/unCheck.svg"
                            ></Image>
                        </Stack>
                    </HStack>

                    <Stack
                        p="3px"
                        borderRadius="2px"
                        cursor="pointer"
                        _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                        onClick={() => {
                            dispatch.pro.setIsShowAllOrders(!pro.isShowAllOrders);
                        }}
                    >
                        <Image
                            boxSize="11px"
                            src={pro.isShowAllOrders ? '/assets/pro/closeFullScreen.svg' : '/assets/pro/fullScreen.svg'}
                        ></Image>
                    </Stack>
                </HStack>
            </TabList>

            <TabPanels>
                <TabPanel h={pro.isShowAllOrders ? '520px' : '160px'} py="0px">
                    <CurrentOrders themeColor={themeColor}></CurrentOrders>
                </TabPanel>
                <TabPanel h={pro.isShowAllOrders ? '520px' : '160px'} py="0px">
                    <PastOrders themeColor={themeColor}></PastOrders>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default Orders;
