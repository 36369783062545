import { useState } from 'react';
import { Box, Button, Grid, Stack, useColorMode, HStack, useBreakpointValue, Image } from '@chakra-ui/react';
import CustomButton from '../../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ToastLink, useCustomToast } from '../../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import CountDownBlock from '../../../../../../iZUMi-UI-toolkit/src/components/CountDownBlock/CountDownBlock';
import Info from '../../../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';
import { UnstakeButton } from './UnstakeButton';
import { StakeButton } from './StakeButton';
import { Earned } from '../../../components/Earned';
import { usePoolEntry } from './PoolListEntry';
import { Text } from '../../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { getChain, getScanUrlPrefix, getTxLink } from '../../../../../../config/chains';
import { useRematchDispatch } from '../../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../../state/store';
import { RewardOrPriceRange } from '../RewardOrPriceRange';
import { formatNumber } from '../../../../../../utils/tokenMath';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import { FarmRefreshHandle } from '../PoolList';
import { getColorThemeSelector } from '../../../../../../utils/funcs';
import { useWeb3React } from '@web3-react/core';
import { collectReward, estimateCollectRewardGas } from '../../../../../../net/contractCall/farm/fixRange/collectReward';
import { i_text_copy_bold } from '../../../../../../style';
import { PositionDetailModal } from '../PositionDetailModal';
import { BoostModal } from '../BoostModal';
import { StakeModal } from '../StakeModal';
import { StakeModalVeiZi } from '../StakeModalVeiZi';
import { getNftUrl, links } from '../../../../../../config/links';
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../../../../../config/miscellaneous/uniswapContracts';
import BigNumber from 'bignumber.js';
import { useGasPrice } from '../../../../../../hooks/useGasPrice';
import AddLiquidityModal from '../AddLiquidityModal';
import { ChainId, FarmFixRangeiZiContractVersion } from '../../../../../../types/mod';
import { FARM_CONFIG } from '../../../../../../config/bizConfig';
import { VeNFTBlock } from '../../../../components/VeNFTBlock';
import { useMiningFixRangeiZiContract } from '../../../../../../utils/contractHelpers';
import { LiquidityEntry } from '../../../../../../state/models/farm/iZiSwap/fixRange/types';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { miningPoolConfigList } from '../../../../../../config/farm/iZiSwap/fixRange/miningPoolMetaConst';

export const PositionList: React.FC<FarmRefreshHandle> = ({ refreshPosition, refreshPoolListDataAndPosition, type }) => {
    const entry = usePoolEntry();
    const positionList = [...(entry.liquidityList ?? []), ...(entry.stakedLiquidityList ?? [])];
    const { account } = useWeb3React();
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const is2XL = oneLineMode;
    const isLoggedIn = !!account;

    const { chainId } = useWeb3WithDefault();
    const uniswapNftAddr = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId];

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const theme = colorTheme('1px solid #E9E9E9', '1px solid #52466A');
    const isMobile = useIsMobile();
    const isNeedApprove = !entry.userData.isApprovedForAll;
    const miningContract = useMiningFixRangeiZiContract(entry.meta.miningContract, entry.meta.contractVersion);

    const toast = useCustomToast();
    const { gasPrice } = useGasPrice();

    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showBoostModal, setShowBoostModal] = useState(false);
    const [showStakeModal, setShowStakeModal] = useState(false);
    const [showAddLiquidityModal, setShowAddLiquidityModal] = useState(false);
    const [showPosition, setShowPosition] = useState({} as LiquidityEntry);
    const [positionIndex, setPositionIndex] = useState<number>(0);

    const isVeiZiVersion = entry.meta.contractVersion === FarmFixRangeiZiContractVersion.VEIZI;

    const viewMiningContract = () => window.open(getScanUrlPrefix(chainId) + entry.meta.miningContract, '_blank');
    const viewMiningContractInAnalytics = () => {
        const findPool = miningPoolConfigList[chainId]?.find((item) => {
            return item.miningContract === entry.meta.miningContract;
        });
        window.open(links.analyticLink + '/pool?chainId=' + chainId + '&poolAddress=' + findPool?.swapPoolAddress);
    };

    const noPosition = positionList.length === 0 && !entry.data.isEnded;
    const GetNFT = () => {
        dispatch.farmFixRangeiZiLiquidity.refreshWalletMintLiquidity({
            liquidityPoolKey: entry.meta.liquidityPoolKey,
            chainId: chainId as unknown as any,
        });
        setShowAddLiquidityModal(true);
    };

    const DetailsButton = (index: any) =>
        isMobile ? (
            <CustomButton
                variant="tertiary"
                text={t('Details')}
                className={i_text_copy_bold}
                gridRow="span 3"
                mb="2px"
                w="64px"
                h={is2XL ? '30px' : '24px'}
                bg={colorTheme('#C1CFFF', 'rgba(84, 103, 170, 0.4)')}
                color={colorTheme('#6171C2', '#919DDD')}
                fontFamily="Graphik"
                fontWeight="600"
                marginLeft="30px"
                onClick={() => {
                    setPositionIndex(index);
                    setShowPosition({ ...positionList[index] });
                    setShowDetailModal(true);
                }}
            />
        ) : (
            <CustomButton
                variant="tertiary"
                text={t('Details')}
                className={i_text_copy_bold}
                gridRow="span 3"
                mb="2px"
                w="64px"
                h={is2XL ? '30px' : '24px'}
                marginLeft="30px"
                onClick={() => {
                    setPositionIndex(index);
                    setShowPosition({ ...positionList[index] });
                    setShowDetailModal(true);
                }}
            />
        );

    const BoostButton = (index: any) => (
        <CustomButton
            variant="purple"
            text={t('Boost')}
            className={i_text_copy_bold}
            gridRow="span 3"
            mb="2px"
            w="64px"
            h={is2XL ? '30px' : '24px'}
            ml={{ base: '0px !important', sm: '30px !important' }}
            onClick={() => {
                setPositionIndex(index);
                setShowBoostModal(true);
            }}
        />
    );

    const StakeModalButton = (index: any, item: LiquidityEntry) => (
        <CustomButton
            variant="tertiary"
            text={t('Stake')}
            className={i_text_copy_bold}
            gridRow={{ base: 'span 2', sm: 'span 3' }}
            mb="2px"
            w={{ base: '90px', sm: '94px' }}
            h={{ base: '30px', sm: '40px' }}
            ml={{ base: 'auto', sm: '30px' }}
            mr={{ base: '15px', sm: '0px' }}
            onClick={() => {
                setPositionIndex(index);
                setShowStakeModal(true);
                if (isVeiZiVersion) {
                    dispatch.veiZiFarmFixRangeStake.refreshMintForm({
                        positionPoolKey: entry.meta.liquidityPoolKey,
                    });
                    console.log('nftId: ', item.nftId);
                    console.log('vLiquidity: ', item.vLiquidity);
                    console.log('user liquidity: ', entry.userData.vLiquidity);
                    dispatch.veiZiFarmFixRangeStake.updateStakeNft({
                        nftId: item.nftId,
                        vLiquidity: Number(item.vLiquidity),
                        capital: item.capital,
                        positionPoolKey: entry.meta.liquidityPoolKey,
                        chainId: chainId,
                    });
                }
            }}
        />
    );

    return type === 'mobile' ? (
        <>
            <Stack direction={is2XL ? 'row' : 'column'} spacing={is2XL ? '50px' : { base: '15px', sm: '38px' }} flexGrow={1}>
                <Stack
                    alignItems="center"
                    justifyContent={is2XL && isLoggedIn && positionList.length > 0 ? 'start' : 'start'}
                    pt={is2XL && isLoggedIn && positionList.length > 0 ? '20px' : 'unset'}
                    spacing={{ base: '20px', sm: '21px' }}
                    direction={(is2XL && isLoggedIn && positionList.length > 0) || isMobile ? 'column' : 'row'}
                >
                    <CountDownBlock initialSeconds={entry.data.secondsLeft} type="mobile" />

                    <Stack direction={{ base: 'row', sm: 'column' }} spacing={{ base: '10px', sm: '21px' }}>
                        {!entry.data.isEnded && (
                            <Button w="140px" flexShrink={0} onClick={GetNFT} bg={colorTheme('#F1F5F9', '#363457')}>
                                <Text variant="caption-bold" color={colorTheme('#687692', '#ADA7D3')}>
                                    {t('Get SoSwap NFT')}
                                </Text>
                            </Button>
                        )}
                        <Button w="140px" flexShrink={0} onClick={viewMiningContract} bg={colorTheme('#F1F5F9', '#363457')}>
                            <Text variant="caption-bold" color={colorTheme('#687692', '#ADA7D3')}>
                                {t('View Contract')}
                            </Text>
                        </Button>
                    </Stack>
                    <Button w="100%" bg={colorTheme('#F7F4FF', '#4A3B6B')} onClick={viewMiningContractInAnalytics}>
                        <Image boxSize="13px" src="/assets/farm/dashboard.svg"></Image>
                        <Text variant="caption-bold" color={colorTheme('#8E5DF5', '#8E63EA')} ml="6px">
                            {t('Dashboard')}
                        </Text>
                    </Button>
                    {oneLineMode && (
                        <Text variant="caption" color="tertiary.500" w={is2XL && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}>
                            {noPosition ? t("You don't have any SoSwap  position for now, click 'Get SoSwap NFT' to start farming.") : ''}
                        </Text>
                    )}
                </Stack>

                {!oneLineMode && noPosition && (
                    <Text variant="caption" color="tertiary.500" w={is2XL && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}>
                        {noPosition ? t("You don't have any SoSwap  position for now, click 'Get SoSwap NFT' to start farming.") : ''}
                    </Text>
                )}

                <Box flexGrow={1}>
                    {positionList.map((item, index) => (
                        <Card
                            pt="10px"
                            mt="13px"
                            key={index}
                            alignItems="start"
                            bg={colorTheme(
                                'linear-gradient(129.24deg, rgba(183, 203, 255, 0.7) -3.64%, rgba(255, 255, 255, 0) 15.01%), linear-gradient(180deg, rgba(169, 188, 255, 0.2) 0%, rgba(188, 200, 232, 0.09) 70.11%), linear-gradient(180deg, #FFFFFF 0%, #FEFEFF 100%)',
                                '#201B36'
                            )}
                            border={colorTheme('0.6px solid #F0F0F0', '1px solid #4F4991')}
                            borderRadius="6px"
                            _notLast={{
                                borderBottom: theme,
                            }}
                        >
                            <HStack spacing="10px" pl="22px">
                                <NFTId
                                    direction={'row'}
                                    id={item.nftId}
                                    w={'130px'}
                                    link={getNftUrl(chainId, uniswapNftAddr, item.nftId)}
                                />
                                {DetailsButton(index)}
                            </HStack>
                            <Info
                                direction={is2XL ? 'column' : 'row'}
                                label={t('TVL')}
                                value={item.vLiquidity === undefined ? '...' : `$${formatNumber(item.TVL)}`}
                                w={oneLineMode ? '121px' : 'unset'}
                                mt="7px"
                                valueStyle={{ fontFamily: 'Graphik', fontSize: '12px' }}
                                pl="22px"
                                labelColor="#A398BD"
                            ></Info>

                            {!entry.meta.iZiBoost ? (
                                <RewardOrPriceRange
                                    data={item}
                                    meta={entry.meta}
                                    label={t('Price Range')}
                                    w={is2XL ? '147px' : { base: 'unset', sm: '247px' }}
                                    handleToggle={() => {
                                        dispatch.farmFixRange.togglePoolMetaInitialToggle(entry.meta.liquidityPoolKey);
                                    }}
                                />
                            ) : item.isStaked ? (
                                <HStack
                                    spacing="10px"
                                    w={is2XL ? '170px' : { base: 'unset', sm: '247px' }}
                                    h={is2XL ? 'unset' : { base: 'unset', sm: '35px' }}
                                    pl="22px"
                                    pb="12px"
                                >
                                    <Info
                                        direction={'row'}
                                        label={t('My APR')}
                                        value={
                                            (item.currentAPR ?? 0) < 99.99
                                                ? formatNumber((item.currentAPR ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) +
                                                  '%'
                                                : '9999+%'
                                        }
                                        w={'143px'}
                                        bg={colorTheme(
                                            'linear-gradient(90.7deg, #D8C8FF 0.27%, rgba(236, 228, 255, 0.49) 124.53%)',
                                            'linear-gradient(90deg, rgba(99, 31, 245, 0.3) 0%, rgba(97, 60, 143, 0.12) 113.29%);'
                                        )}
                                        borderRadius="2px"
                                        color={colorTheme('secondary.600', '#F7EEFF')}
                                        valueStyle={{ fontSize: '12px' }}
                                    />
                                    {!entry.data.isEnded && (
                                        <HStack w="100%" bg={colorTheme('inherit', 'rgba(50, 46, 91, 0.3)')}>
                                            {BoostButton(index)}
                                        </HStack>
                                    )}
                                </HStack>
                            ) : (
                                <HStack
                                    spacing={{ base: '0px', sm: '10px' }}
                                    w={is2XL ? '170px' : { base: 'unset', sm: '247px' }}
                                    h={is2XL ? 'unset' : { base: '32px', sm: '32px' }}
                                />
                            )}

                            {!isNeedApprove ? (
                                !entry.meta.veiZiBoost ? (
                                    <Earned
                                        gridRow={item.isStaked ? 'span 3' : { base: 'span 2', sm: 'span 3' }}
                                        buttonProps={{
                                            mt: { base: '10px !important', sm: '0px !important' },
                                            w: '90px',
                                            h: '30px',
                                            bg: colorTheme('#E2EAF2', '#363457'),
                                            color: colorTheme('#687692', '#B6C6E7'),
                                        }}
                                        entry={entry}
                                        tokenId={item.nftId}
                                        onHarvest={() => {
                                            if (miningContract && account) {
                                                const chain = getChain(chainId);
                                                const toastLink = {} as ToastLink;
                                                estimateCollectRewardGas(miningContract, account, Number(item.nftId)).then(
                                                    (gas: number) => {
                                                        const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                                                        collectReward(
                                                            chainId as ChainId,
                                                            miningContract,
                                                            account,
                                                            Number(item.nftId),
                                                            gasLimit,
                                                            gasPrice
                                                        )
                                                            .on('transactionHash', (hash: string) => {
                                                                if (chain) {
                                                                    toastLink.title = 'View on ' + chain.name;
                                                                    toastLink.link = getTxLink(hash, chain);
                                                                }
                                                                toast('info', 'Collecting rewards ...', undefined, toastLink);
                                                            })
                                                            .then(() => {
                                                                toast('success', 'Collect reward successfully.', undefined, toastLink);
                                                                console.log('collectReward success');
                                                                refreshPosition?.();
                                                            });
                                                    }
                                                );
                                            }
                                        }}
                                        earned={
                                            item.earned &&
                                            item.earned.map((item) => {
                                                return parseFloat(item);
                                            })
                                        }
                                        flexDirection={'row'}
                                        alignItems="start"
                                        pl="22px"
                                        bg={colorTheme('#FFFFFF', 'rgba(50, 46, 91, 0.3)')}
                                    />
                                ) : (
                                    <HStack w={oneLineMode ? '160px' : '247px'} h={oneLineMode ? '54px' : '32px'}></HStack>
                                )
                            ) : (
                                <Text variant="caption" color="tertiary.500" ml="140px">
                                    Waiting for approve...
                                </Text>
                            )}

                            {item.isStaked ? (
                                <HStack w="100%" h="40px" bg={colorTheme('#FFFFFF', 'rgba(50, 46, 91, 0.3)')} pb="9px">
                                    <UnstakeButton
                                        tokenId={item.nftId}
                                        onSuccess={refreshPoolListDataAndPosition}
                                        gridRow={{ base: 'span 1', sm: 'span 3' }}
                                        mb="2px"
                                        w="90px"
                                        h="30px"
                                        ml="auto"
                                        mr="15px"
                                    />
                                </HStack>
                            ) : entry.meta.iZiBoost || entry.meta.veiZiBoost ? (
                                !entry.data.isEnded && !isNeedApprove && StakeModalButton(index, item)
                            ) : (
                                !isNeedApprove &&
                                !entry.data.isEnded && (
                                    <StakeButton
                                        tokenId={item.nftId}
                                        onSuccess={refreshPoolListDataAndPosition}
                                        gridRow={{ base: 'span 2', sm: 'span 3' }}
                                        mb="2px"
                                        w="90px"
                                        h="30px"
                                        ml="auto"
                                        mr="15px"
                                    />
                                )
                            )}
                        </Card>
                    ))}
                    {positionList.length > 0 && entry.meta.veiZiBoost && (
                        <Grid
                            mt="20px"
                            alignItems="center"
                            templateRows={{ base: 'repeat(1, 1fr)', sm: `repeat(${oneLineMode ? 1 : 3}, 1fr)` }}
                            templateColumns={`repeat(${oneLineMode ? 4 : 2}, max-content) auto`}
                            autoFlow={oneLineMode ? 'row' : 'column'}
                            _notLast={{
                                borderBottom: theme,
                            }}
                            _last={{
                                marginBottom: { base: '0px', sm: '-26px' },
                            }}
                        >
                            <Stack direction={oneLineMode ? 'row' : 'column'}>
                                <Card variant="grey" p="10px 40px 20px 40px" h="60px" w="130px">
                                    <Info
                                        direction="column"
                                        label={t('My APR')}
                                        value={
                                            entry.userData.apr <= 99.99
                                                ? formatNumber(entry.userData.apr * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w="60px"
                                    />
                                </Card>
                                <VeNFTBlock
                                    w={{ base: '100%', sm: 'unset' }}
                                    h={{ base: 'unset', sm: '60px' }}
                                    p={{ base: '10px 20px 20px 20px', sm: '10px 40px 20px 40px' }}
                                    entry={
                                        entry.userData.veiZiNftId === '0'
                                            ? undefined
                                            : {
                                                  nftId: entry.userData.veiZiNftId,
                                                  veiZiDecimal: entry.userData.veiZiDecimal,
                                              }
                                    }
                                />
                            </Stack>
                        </Grid>
                    )}
                </Box>
            </Stack>

            {showDetailModal && (
                <PositionDetailModal
                    isOpen={showDetailModal}
                    entry={entry}
                    liquidity={showPosition}
                    onClose={() => {
                        setShowDetailModal(false);
                    }}
                />
            )}

            {showBoostModal && (
                <BoostModal
                    isOpen={showBoostModal}
                    entry={entry}
                    index={positionIndex}
                    onClose={() => {
                        setShowBoostModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showStakeModal && !isVeiZiVersion && (
                <StakeModal
                    isOpen={showStakeModal}
                    entry={entry}
                    index={positionIndex}
                    onClose={() => {
                        setShowStakeModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showStakeModal && isVeiZiVersion && (
                <StakeModalVeiZi
                    isOpen={showStakeModal}
                    entry={entry}
                    index={positionIndex}
                    onClose={() => {
                        setShowStakeModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showAddLiquidityModal && (
                <AddLiquidityModal
                    isOpen={showAddLiquidityModal}
                    onClose={() => {
                        setShowAddLiquidityModal(false);
                    }}
                    onSuccess={refreshPosition}
                    entry={entry}
                />
            )}
        </>
    ) : (
        <>
            <Stack direction={is2XL ? 'row' : 'column'} spacing={is2XL ? '50px' : { base: '15px', sm: '38px' }} flexGrow={1}>
                <Stack
                    alignItems="center"
                    justifyContent={is2XL && isLoggedIn && positionList.length > 0 ? 'start' : 'start'}
                    pt={is2XL && isLoggedIn && positionList.length > 0 ? '20px' : 'unset'}
                    spacing={{ base: '8px', xl: '21px' }}
                    direction={(is2XL && isLoggedIn && positionList.length > 0) || isMobile ? 'column' : 'row'}
                >
                    {!entry.data.isEnded && (
                        <Button w="140px" flexShrink={0} onClick={GetNFT}>
                            <Text variant="caption-bold">{t('Get SoSwap NFT')}</Text>
                        </Button>
                    )}
                    <Button w="140px" flexShrink={0} onClick={viewMiningContract}>
                        <Text variant="caption-bold">{t('View Contract')}</Text>
                    </Button>
                    {!noPosition && (
                        <Button w="140px" flexShrink={0} bg={colorTheme('#F7F4FF', '#4A3B6B')} onClick={viewMiningContractInAnalytics}>
                            <Image boxSize="13px" src="/assets/farm/dashboard.svg"></Image>

                            <Text variant="caption-bold" color={colorTheme('#8E5DF5', '#8E63EA')} ml="6px">
                                {t('Dashboard')}
                            </Text>
                        </Button>
                    )}
                    <CountDownBlock initialSeconds={entry.data.secondsLeft} flexShrink={0} w="160px" />
                    {oneLineMode && (
                        <HStack>
                            {noPosition && (
                                <Image
                                    boxSize="35px"
                                    src={colorTheme('/assets/trade/limitOrderDashboard.svg', '/assets/trade/darklimitOrderDashboard.svg')}
                                    cursor="pointer"
                                    onClick={viewMiningContractInAnalytics}
                                ></Image>
                            )}

                            <Text
                                variant="caption"
                                color="tertiary.500"
                                w={is2XL && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}
                                ml="10px !important"
                            >
                                {noPosition
                                    ? t("You don't have any SoSwap  position for now, click 'Get SoSwap NFT' to start farming.")
                                    : ''}
                            </Text>
                        </HStack>
                    )}
                </Stack>

                {!oneLineMode && noPosition && (
                    <HStack>
                        {noPosition && (
                            <Image
                                boxSize="35px"
                                src={colorTheme('/assets/trade/limitOrderDashboard.svg', '/assets/trade/darklimitOrderDashboard.svg')}
                                cursor="pointer"
                                onClick={viewMiningContractInAnalytics}
                            ></Image>
                        )}
                        <Text
                            variant="caption"
                            color="tertiary.500"
                            w={oneLineMode && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}
                        >
                            {noPosition ? t("You don't have any SoSwap  position for now, click 'Get SoSwap NFT' to start farming.") : ''}
                        </Text>
                    </HStack>
                )}

                <Box flexGrow={1}>
                    {positionList.map((item, index) => (
                        <Grid
                            py="10px"
                            key={index}
                            alignItems="center"
                            templateRows={isMobile ? `repeat(${is2XL ? 5 : 4}, 1fr)` : `repeat(${is2XL ? 1 : 3}, 1fr)`}
                            templateColumns={`repeat(${is2XL ? 4 : 2}, max-content) auto`}
                            autoFlow={is2XL ? 'row' : 'column'}
                            _notLast={{
                                borderBottom: theme,
                            }}
                            _last={{
                                marginBottom: '-26px',
                            }}
                        >
                            <HStack spacing="10px" w={{ base: '137px', sm: '190px' }}>
                                <NFTId
                                    direction={is2XL ? 'column' : isMobile ? 'column' : 'row'}
                                    id={item.nftId}
                                    w={oneLineMode ? '73px' : { base: '55px', sm: 'unset' }}
                                    link={getNftUrl(chainId, uniswapNftAddr, item.nftId)}
                                />
                                {DetailsButton(index)}
                            </HStack>
                            <Info
                                direction={is2XL ? 'column' : 'row'}
                                label={t('TVL')}
                                value={item.vLiquidity === undefined ? '...' : `$${formatNumber(item.TVL)}`}
                                w={oneLineMode ? '121px' : 'unset'}
                            ></Info>

                            {!entry.meta.iZiBoost ? (
                                <RewardOrPriceRange
                                    data={item}
                                    meta={entry.meta}
                                    label={t('Price Range')}
                                    w={is2XL ? '147px' : { base: 'unset', sm: '247px' }}
                                    handleToggle={() => {
                                        dispatch.farmFixRange.togglePoolMetaInitialToggle(entry.meta.liquidityPoolKey);
                                    }}
                                />
                            ) : item.isStaked ? (
                                <HStack
                                    spacing="10px"
                                    w={is2XL ? '170px' : { base: 'unset', sm: '247px' }}
                                    h={is2XL ? 'unset' : { base: 'unset', sm: '32px' }}
                                >
                                    <Info
                                        direction={is2XL || isMobile ? 'column' : 'row'}
                                        label={t('My APR')}
                                        value={
                                            (item.currentAPR ?? 0) < 99.99
                                                ? formatNumber((item.currentAPR ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) +
                                                  '%'
                                                : '9999+%'
                                        }
                                        w={oneLineMode ? '70px' : { base: '55px', sm: 'unset' }}
                                    />
                                    {!entry.data.isEnded && BoostButton(index)}
                                </HStack>
                            ) : (
                                <HStack
                                    spacing={{ base: '0px', sm: '10px' }}
                                    w={is2XL ? '170px' : { base: 'unset', sm: '247px' }}
                                    h={is2XL ? 'unset' : { base: '32px', sm: '32px' }}
                                />
                            )}

                            {!isNeedApprove ? (
                                !entry.meta.veiZiBoost ? (
                                    <Earned
                                        gridRow={item.isStaked ? 'span 3' : { base: 'span 2', sm: 'span 3' }}
                                        buttonProps={{ mt: { base: '10px !important', sm: '0px !important' } }}
                                        entry={entry}
                                        tokenId={item.nftId}
                                        onHarvest={() => {
                                            if (miningContract && account) {
                                                estimateCollectRewardGas(miningContract, account, Number(item.nftId)).then(
                                                    (gas: number) => {
                                                        const chain = getChain(chainId);
                                                        const toastLink = {} as ToastLink;
                                                        const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                                                        collectReward(
                                                            chainId as ChainId,
                                                            miningContract,
                                                            account,
                                                            Number(item.nftId),
                                                            gasLimit,
                                                            gasPrice
                                                        )
                                                            .on('transactionHash', (hash: string) => {
                                                                if (chain) {
                                                                    toastLink.title = 'View on ' + chain.name;
                                                                    toastLink.link = getTxLink(hash, chain);
                                                                }
                                                                toast('info', 'Collecting rewards ...', undefined, toastLink);
                                                            })
                                                            .then(() => {
                                                                toast('success', 'Collect reward successfully.', undefined, toastLink);
                                                                console.log('collectReward success');
                                                                refreshPosition?.();
                                                            });
                                                    }
                                                );
                                            }
                                        }}
                                        earned={
                                            item.earned &&
                                            item.earned.map((item) => {
                                                return parseFloat(item);
                                            })
                                        }
                                        flexDirection={{ base: 'column', sm: 'row' }}
                                    />
                                ) : (
                                    <HStack w={oneLineMode ? '160px' : '247px'} h={oneLineMode ? '54px' : '32px'}></HStack>
                                )
                            ) : (
                                <Text variant="caption" color="tertiary.500" ml="140px">
                                    Waiting for approve...
                                </Text>
                            )}

                            {item.isStaked ? (
                                <UnstakeButton
                                    tokenId={item.nftId}
                                    onSuccess={refreshPoolListDataAndPosition}
                                    gridRow={{ base: 'span 1', sm: 'span 3' }}
                                    mb="2px"
                                    w="96px"
                                    h="40px"
                                    marginLeft="30px"
                                />
                            ) : entry.meta.iZiBoost || entry.meta.veiZiBoost ? (
                                !entry.data.isEnded && !isNeedApprove && StakeModalButton(index, item)
                            ) : (
                                !isNeedApprove &&
                                !entry.data.isEnded && (
                                    <StakeButton
                                        tokenId={item.nftId}
                                        onSuccess={refreshPoolListDataAndPosition}
                                        gridRow={{ base: 'span 2', sm: 'span 3' }}
                                        mb="2px"
                                        w="96px"
                                        h="40px"
                                        marginLeft="30px"
                                    />
                                )
                            )}
                        </Grid>
                    ))}
                    {positionList.length > 0 && entry.meta.veiZiBoost && (
                        <Grid
                            mt="20px"
                            alignItems="center"
                            templateRows={{ base: 'repeat(1, 1fr)', sm: `repeat(${oneLineMode ? 1 : 3}, 1fr)` }}
                            templateColumns={`repeat(${oneLineMode ? 4 : 2}, max-content) auto`}
                            autoFlow={oneLineMode ? 'row' : 'column'}
                            _notLast={{
                                borderBottom: theme,
                            }}
                            _last={{
                                marginBottom: { base: '0px', sm: '-26px' },
                            }}
                        >
                            <Stack direction={oneLineMode ? 'row' : 'column'}>
                                <Card variant="grey" p="10px 40px 20px 40px" h="60px" w="130px">
                                    <Info
                                        direction="column"
                                        label={t('My APR')}
                                        value={
                                            entry.userData.apr <= 99.99
                                                ? formatNumber(entry.userData.apr * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w="60px"
                                    />
                                </Card>
                                <VeNFTBlock
                                    w={{ base: '100%', sm: 'unset' }}
                                    h={{ base: 'unset', sm: '60px' }}
                                    p={{ base: '10px 20px 20px 20px', sm: '10px 40px 20px 40px' }}
                                    entry={
                                        entry.userData.veiZiNftId === '0'
                                            ? undefined
                                            : {
                                                  nftId: entry.userData.veiZiNftId,
                                                  veiZiDecimal: entry.userData.veiZiDecimal,
                                              }
                                    }
                                />
                            </Stack>
                        </Grid>
                    )}
                </Box>
            </Stack>

            {showDetailModal && (
                <PositionDetailModal
                    isOpen={showDetailModal}
                    entry={entry}
                    liquidity={showPosition}
                    onClose={() => {
                        setShowDetailModal(false);
                    }}
                />
            )}

            {showBoostModal && (
                <BoostModal
                    isOpen={showBoostModal}
                    entry={entry}
                    index={positionIndex}
                    onClose={() => {
                        setShowBoostModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showStakeModal && !isVeiZiVersion && (
                <StakeModal
                    isOpen={showStakeModal}
                    entry={entry}
                    index={positionIndex}
                    onClose={() => {
                        setShowStakeModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showStakeModal && isVeiZiVersion && (
                <StakeModalVeiZi
                    isOpen={showStakeModal}
                    entry={entry}
                    index={positionIndex}
                    onClose={() => {
                        setShowStakeModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showAddLiquidityModal && (
                <AddLiquidityModal
                    isOpen={showAddLiquidityModal}
                    onClose={() => {
                        setShowAddLiquidityModal(false);
                    }}
                    onSuccess={refreshPosition}
                    entry={entry}
                />
            )}
        </>
    );
};
