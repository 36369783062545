import React, { useEffect } from 'react';
import { Divider, HStack, Stack, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../../../../components/layout/PageLayout';
import PageHeader from '../../../../../components/PageHeader/PageHeader';
import useIsMobile from '../../../../../hooks/useIsMobile';
import BackButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';
import ChainNotValidBlockWrapper from '../../../../components/ChainNotValidBlock';
import AddLiquidityForm from '../AddLiquidityForm';
import { Heading, Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_d } from '../../../../../style';
import { useHistory } from 'react-router-dom';
import { getColorThemeSelector } from '../../../../../utils/funcs';

const AddCustomLiquidity: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const history = useHistory();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%" mt="20px">
                            <BackButton flex={20} />
                            <Stack flex={80} alignItems="center" mt="14px">
                                <Heading level={'4'}>{t('Add Liquidity')}</Heading>
                                <Text variant="display" fontSize="12px">
                                    {t('Add a new position to earn transaction fees.')}
                                </Text>
                                <CustomButton
                                    variant="lightPurple"
                                    className={i_text_d}
                                    fontSize="14px"
                                    fontWeight="600"
                                    color={colorTheme('#8000FF', '#ECDAFF')}
                                    bg={colorTheme(
                                        'linear-gradient(90.61deg, rgba(230, 220, 255, 0.71) -19.1%, #F2E2FF 160.54%, rgba(76, 70, 90, 0.38) 223.79%)',
                                        'linear-gradient(90.61deg, rgba(70, 49, 121, 0.71) -19.1%, #421267 160.54%, rgba(76, 70, 90, 0.38) 223.79%)'
                                    )}
                                    _hover={{ opacity: 0.7 }}
                                    w="180px"
                                    h="36px"
                                    text="Popular >>"
                                    onClick={() => {
                                        history.push('/add-liquidity/popular');
                                    }}
                                ></CustomButton>
                            </Stack>
                            <Divider></Divider>
                            <AddLiquidityForm />
                        </Stack>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="14px">
                            <Heading level={isMobile ? '4' : '3'}>{t('Add Liquidity')}</Heading>
                            <CustomButton
                                variant="lightPurple"
                                className={i_text_d}
                                fontSize="14px"
                                fontWeight="600"
                                color={colorTheme('#8000FF', '#ECDAFF')}
                                bg={colorTheme(
                                    'linear-gradient(90.61deg, rgba(230, 220, 255, 0.71) -19.1%, #F2E2FF 160.54%, rgba(76, 70, 90, 0.38) 223.79%)',
                                    'linear-gradient(90.61deg, rgba(70, 49, 121, 0.71) -19.1%, #421267 160.54%, rgba(76, 70, 90, 0.38) 223.79%)'
                                )}
                                _hover={{ opacity: 0.7 }}
                                w="180px"
                                h="36px"
                                text="Popular >>"
                                onClick={() => {
                                    history.push('/add-liquidity/popular');
                                }}
                            ></CustomButton>
                        </HStack>
                    }
                    subtitle={t('Add a new position to earn transaction fees.')}
                    variant="secondary"
                    alignItems="start"
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%">
                            <AddLiquidityForm />
                        </Stack>
                    }
                    app="trade"
                />
            }
            internalWidth={{ base: '700px', xl: '1150px' }}
        />
    );
};

export default AddCustomLiquidity;
