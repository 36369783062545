import axios from 'axios';
import mem from 'mem/dist';
import { useEffect, useState } from 'react';
import { ChainId } from '../types/mod';
import TOKEN_LIST from '../token-list.json';

export interface TokenInfo {
    chains: number[];
    name: string;
    symbol: string;
    icon: string;
    contracts: Partial<Record<ChainId, { address: string; decimal: number }>>;
}

export const getTokenListConfig = (): any[] => {
    //return axios.get(tokenListUrl);
    return TOKEN_LIST;
};

export const memGetTokenListConfig = mem(getTokenListConfig, {
    maxAge: 1 * 60 * 60 * 1000,
});

export type TokenConfigData = {
    loading: boolean;
    tokenConfig: TokenInfo[];
};

const TOKEN_CONFIG_CONTEXT: TokenConfigData = {
    loading: true,
    tokenConfig: [],
};

export const useTokenList = (): {
    loading: boolean;
    tokenList: TokenInfo[];
} => {
    const [loading, setLoading] = useState<boolean>(TOKEN_CONFIG_CONTEXT.loading);

    useEffect(() => {
        const fetchData = async () => {
            if (TOKEN_CONFIG_CONTEXT.tokenConfig.length !== 0) {
                return;
            }
            console.log('fetch tokenList');
            TOKEN_CONFIG_CONTEXT.tokenConfig = getTokenListConfig();
            TOKEN_CONFIG_CONTEXT.loading = false;
            setLoading(false);
        };

        fetchData();
    }, []);

    return {
        loading: loading && TOKEN_CONFIG_CONTEXT.loading,
        tokenList: TOKEN_CONFIG_CONTEXT.tokenConfig,
    };
};

export const getTokenList = (): {
    loading: boolean;
    tokenList: TokenInfo[];
} => {
    let loading = true;

    const fetchData = async () => {
        if (TOKEN_CONFIG_CONTEXT.tokenConfig.length !== 0) {
            return;
        }
        console.log('fetch tokenList');
        TOKEN_CONFIG_CONTEXT.tokenConfig = getTokenListConfig();
        TOKEN_CONFIG_CONTEXT.loading = false;
        loading = false;
    };

    fetchData();

    return {
        loading: loading && TOKEN_CONFIG_CONTEXT.loading,
        tokenList: TOKEN_CONFIG_CONTEXT.tokenConfig,
    };
};
