import { Center, Flex, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useColorMode } from '@chakra-ui/react';
import React, { useState } from 'react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { links } from '../../../../config/links';
import placeholder from '../../../../assets/placeholder.png';
import { i_h1, i_text_copy_bold, i_text_d } from '../../../../style';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { ChainId } from '../../../../types/mod';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import { useHover } from '../../../../iZUMi-UI-toolkit/src/hooks/useHover';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import useLayoutType from '../../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../../state/models/pageLayout/pageLayout';
import { useHistory } from 'react-router-dom';

const HomePiece1: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const layoutType = useLayoutType();
    const history = useHistory();

    const isMobile = useIsMobile();
    const [ref, isHovering] = useHover<HTMLDivElement>();
    const [isOpenList, setOpenList] = useState(false);

    return (
        <Flex
            backgroundSize="cover"
            w="100%"
            h={{ base: 'unset', md: '548px', xl: layoutType === LayoutStateEnum.sidebar ? '620px' : '584px' }}
            p={{
                base: '125px 20px 0px 20px',
                xl: layoutType === LayoutStateEnum.sidebar ? '135px 114px 394px 114px' : '35px 114px 394px 114px',
            }}
            boxSizing="border-box"
            overflow="hidden"
        >
            <Flex
                w={{ base: '363px', md: '710px', xl: '967px' }}
                direction={{ base: 'column', md: 'row' }}
                margin="auto"
                alignItems="center"
                textAlign={{ base: 'left', xl: 'unset' }}
            >
                <Flex w={{ base: '100%', xl: '467px' }} mr="0px" flex={50} direction="column">
                    <HStack alignItems="start" justifyContent={{ base: 'center', sm: 'start' }} position="relative">
                        <HStack mr={{ base: '25px !important', sm: '0px !important' }}>
                            <Image
                                w={{ base: 'unset', lg: '85px' }}
                                h={{ base: '57px', md: '62px', lg: '89px' }}
                                src="/assets/home/soswapLogo/logo.svg"
                                fallbackSrc={process.env.PUBLIC_URL + '/assets/home/soswapLogo/logo.svg'}
                            ></Image>
                            <Text
                                className={i_h1}
                                w={{ base: 'unset', sm: '240px', md: '240px', lg: '240px' }}
                                color={colorTheme('#1D0928', '#F5F5F5')}
                                ml="15px !important"
                            >
                                Soswap Pro
                            </Text>
                        </HStack>
                    </HStack>
                    <Text w={{ base: '100%', xl: '467px' }} className={i_text_d} mt="32px" color={colorTheme('tertiary.700', 'tertiary.300')}>
                        {t(
                            'Incorporated with the innovative Discretized-Liquidity-AMM model, RAI Finance’s Swap on multi-chains to maximize capital efficiency by realizing Limit Order / Pro mode / Analytics in a decentralized way.'
                        )}
                    </Text>

                    <Flex w="100%" direction="row" mt={{ base: '30px', md: '45px' }} justifyContent={{ base: 'center', md: 'unset' }}>
                        <>
                            <CustomButton
                                w={{ base: '110px', md: '125px', xl: '178px' }}
                                h="40px"
                                variant="purple"
                                text={t('Go Trade')}
                                className={i_text_copy_bold}
                                bg={'#4F54CF'}
                                _hover={{ bg: '#3C45F4' }}
                                onClick={() => {
                                    history.push('/swap');
                                }}
                            ></CustomButton>

                            <CustomButton
                                w={{ base: '110px', md: '125px', xl: '178px' }}
                                h="40px"
                                // ml="10px"
                                ml={{ base: '10px', md: '13px', xl: '16px' }}
                                variant="primary3"
                                text={t('Add Liqudity')}
                                className={i_text_copy_bold}
                                bg={colorTheme('#F0F0FB', '#24202C')}
                                _hover={{ bg: colorTheme('#e1e1ff', '#443c53') }}
                                onClick={async () => {
                                    history.push('/add-liquidity/popular');
                                }}
                            ></CustomButton>
                        </>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default HomePiece1;
