import { Divider, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { noto_h3, noto_t3_bold } from '../../../../style';
import OrderBookBlock from './components/OrderBookBlock';
import RecentTradesBlock from './components/RecentTradesBlock';

export type trades = {
    actionType: string;
    price: number | undefined;
    quantity: number | undefined;
    timestamp: number;
};

type OrderBookProps = {
    themeColor: any;
    trades: trades[];
};

const OrderBook: React.FC<OrderBookProps> = (props) => {
    const { themeColor, trades } = props;
    const { t } = useTranslation();
    const [currentType, setCurrentType] = useState('OrderBook');
    const is2XL = useBreakpointValue({ base: false, xxl: false, '2xl': true })!;

    return is2XL ? (
        <Stack h="100%" direction="row" overflow="hidden" borderRadius="6px">
            <Stack w="300px" bg={themeColor} p="10px">
                <Text className={noto_h3} color="#83789B">
                    {t('Order Book')}
                </Text>
                <Divider></Divider>
                <OrderBookBlock trades={trades}></OrderBookBlock>
            </Stack>
            <Stack w="270px" bg={themeColor} p="10px">
                <Text className={noto_h3} color="#83789B">
                    {t('Recent Trades')}
                </Text>
                <Divider></Divider>
                <RecentTradesBlock trades={trades} themeColor={themeColor}></RecentTradesBlock>
            </Stack>
        </Stack>
    ) : (
        <Stack w="300px" h="100%" bg={themeColor} overflow="hidden" borderRadius="6px">
            <Tabs>
                <TabList justifyContent="5px">
                    <Tab
                        w="90px"
                        color="#A3A0A9"
                        px="2px"
                        _selected={{
                            color: '#5542F6',
                            borderColor: '#5542F6',
                            borderBottomWidth: '3px',
                        }}
                        _hover={{
                            color: '#5542F6',
                        }}
                        onClick={() => {
                            setCurrentType('OrderBook');
                        }}
                    >
                        <Text className={currentType === 'OrderBook' ? noto_t3_bold : noto_h3}>{t('Order Book')}</Text>
                    </Tab>
                    <Tab
                        w="100px"
                        px="2px"
                        color="#A3A0A9"
                        ml="5px"
                        _selected={{
                            color: '#5542F6',
                            borderColor: '#5542F6',
                            borderBottomWidth: '3px',
                        }}
                        _hover={{
                            color: '#5542F6',
                        }}
                        onClick={() => {
                            setCurrentType('RecentTrades');
                        }}
                    >
                        <Text className={currentType === 'RecentTrades' ? noto_t3_bold : noto_h3}>{t('Recent Trades')}</Text>
                    </Tab>
                </TabList>

                <TabPanels>
                    <TabPanel p="10px">
                        <OrderBookBlock trades={trades}></OrderBookBlock>
                    </TabPanel>
                    <TabPanel p="10px">
                        <RecentTradesBlock trades={trades} themeColor={themeColor}></RecentTradesBlock>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
    );
};

export default OrderBook;
