import React, { useEffect } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import PageLayout from '../../../../components/layout/PageLayout';
import { useWeb3React } from '@web3-react/core';
import NotConnectModal from '../../components/NotConnectModal';
import MyLimitOrderControls from './components/Control';
import { MyLimitOrdersContainer } from './components/MyLimitOrdersContainer';
import ChainNotValidBlockWrapper from '../../../components/ChainNotValidBlock';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import BackButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';
import { Heading, Text } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { useTranslation } from 'react-i18next';

const MyOrders: React.FC = () => {
    const { account } = useWeb3React();

    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                account ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Stack w="100%" mt="20px">
                                <BackButton flex={20} />
                                <Stack flex={80} alignItems="center">
                                    <Heading level={'4'}>{t('My Orders')}</Heading>
                                    <Text variant="display" fontSize="12px">
                                        {t('Manage your limit orders.')}
                                    </Text>
                                </Stack>
                                <MyLimitOrderControls />
                                <MyLimitOrdersContainer />
                            </Stack>
                        }
                        app="trade"
                    />
                ) : (
                    <NotConnectModal />
                )
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={<PageHeader headerTitle={t('My Orders')} subtitle={t('Manage your limit orders.')} variant="secondary" />}
            body={
                account ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Stack w="100%">
                                <MyLimitOrderControls />
                                <Box h="40px" />
                                <MyLimitOrdersContainer />
                            </Stack>
                        }
                        app="trade"
                    />
                ) : (
                    <NotConnectModal />
                )
            }
            internalWidth={{
                base: '700px',
                lg: '840px',
                xxl: '1100px',
                xlp1: '1150px',
            }}
        />
    );
};

export default MyOrders;
