import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';
import { ChainId } from '../types/mod';

export interface BaseChain {
    id: number;
    name: string;
    tokenSymbol: string;
    token: Partial<TokenInfoFormatted>;
    wrappedTokenSymbol?: string;
    wrappedToken?: Partial<TokenInfoFormatted>;
    icon: string;
    scanUrl: string;
    scanName: string;
    vmType: string;
    rpcUrl: string;
    blockDelta?: number; // time for producing a new block
    blockDeltaU?: number; // time for producing a new block average
}

const baseURL = '/images/chains/';

const initialChains: BaseChain[] = [
    {
        id: ChainId.EthereumMainnet,
        name: 'Ethereum',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        },
        icon: baseURL + 'eth.png',
        scanUrl: 'https://etherscan.io/',
        scanName: 'EtherScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.infura.io/v3/1d8efe3c81f6403fac6633ef69479a73',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Matic,
        name: 'Polygon',
        tokenSymbol: 'Matic',
        token: {
            symbol: 'MATIC',
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        wrappedTokenSymbol: 'WMATIC',
        wrappedToken: {
            symbol: 'WMATIC',
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        icon: baseURL + 'matic.png',
        scanUrl: 'https://polygonscan.com/',
        scanName: 'PologonScan',
        vmType: 'EVM',
        rpcUrl: 'https://polygon-rpc.com/',
        blockDelta: 2,
        blockDeltaU: 2.2,
    },
    {
        id: ChainId.Rinkeby,
        name: 'Rinkeby',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
        },
        icon: baseURL + 'eth.png',
        scanUrl: 'https://rinkeby.etherscan.io/',
        scanName: 'EtherScan',
        vmType: 'EVM',
        rpcUrl: 'https://rinkeby.infura.io/v3/',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Loot,
        name: 'Lootchain',
        tokenSymbol: 'AGLD',
        token: {
            symbol: 'AGLD',
            address: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        },
        wrappedTokenSymbol: 'WAGLD',
        wrappedToken: {
            symbol: 'WAGLD',
            address: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        },
        icon: baseURL + 'loot.png',
        scanUrl: 'https://explorer.lootchain.com/',
        scanName: 'Loot Mainnet Explorer',
        vmType: 'EVM',
        rpcUrl: 'https://rpc.lootchain.com/http',
    },
    {
        id: ChainId.Base,
        name: 'Base',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0x4200000000000000000000000000000000000006',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0x4200000000000000000000000000000000000006',
        },
        icon: baseURL + 'base.png',
        scanUrl: 'https://basescan.org/',
        scanName: 'base scan',
        vmType: 'EVM',
        rpcUrl: 'https://base-mainnet.g.alchemy.com/v2/a6e-68W-TjtgymZS54xkS0zIowW72VDA',
    },
];

const lookupTableReducer = (table: Dictionary<number>, next: BaseChain, index: number) => {
    table[next.id] = index;
    return table;
};

const chains = (() => {
    const supportedIds = (() => {
        if (process.env.REACT_APP_ENV === 'production') {
            return {
                all: [
                    // ChainId.EthereumMainnet,
                    // ChainId.BSC,
                    // ChainId.Aurora,
                    // ChainId.Arbitrum,
                    // ChainId.ZkSyncEra,
                    // ChainId.Matic,
                    // ChainId.Meter,
                    // ChainId.Telos,
                    //ChainId.Cronos,
                    //ChainId.ETC,
                    //ChainId.ConfluxESpace,
                    ChainId.Base,
                    ChainId.Loot,
                ],
                farmUni: [
                    // ChainId.EthereumMainnet, ChainId.Arbitrum, ChainId.Matic
                ],
                farmiZi: [
                    ChainId.Base,
                    ChainId.Loot,
                    // ChainId.BSC, ChainId.Arbitrum
                ],
                trade: [
                    // ChainId.BSC,
                    // ChainId.Aurora,
                    // ChainId.Arbitrum,
                    // ChainId.ZkSyncEra,
                    // ChainId.Matic,
                    // ChainId.Meter,
                    // ChainId.Cronos,
                    // ChainId.ETC,
                    // ChainId.Telos,
                    //ChainId.Icplaza,
                    //ChainId.ConfluxESpace,
                    ChainId.Base,
                    ChainId.Loot,
                ],
                veiZi: [
                    // ChainId.EthereumMainnet
                ],
                iPoints: [ChainId.Base, ChainId.Loot],
                pools: [ChainId.Base, ChainId.Loot],
            };
        } else {
            return {
                all: [ChainId.Base, ChainId.Loot],
                farmUni: [],
                farmiZi: [ChainId.Base, ChainId.Loot],
                trade: [ChainId.Base, ChainId.Loot],
                veiZi: [],
                iPoints: [ChainId.Base, ChainId.Loot],
                pools: [ChainId.Base, ChainId.Loot],
            };
        }
    })();

    const initialChainsLookupTable = initialChains.reduce(lookupTableReducer, {});
    const reducer = (endChains: BaseChain[], id: number) => {
        if (initialChainsLookupTable[id] !== undefined) {
            endChains.push(initialChains[initialChainsLookupTable[id]]);
        } else {
            console.error(`Chain ID not found: ${id}`);
        }
        return endChains;
    };

    return {
        all: supportedIds.all.reduce<BaseChain[]>(reducer, []),
        farmUni: supportedIds.farmUni.reduce<BaseChain[]>(reducer, []),
        farmiZi: supportedIds.farmiZi.reduce<BaseChain[]>(reducer, []),
        trade: supportedIds.trade.reduce<BaseChain[]>(reducer, []),
        veiZi: supportedIds.veiZi.reduce<BaseChain[]>(reducer, []),
        iPoints: supportedIds.iPoints.reduce<BaseChain[]>(reducer, []),
        pools: supportedIds.pools.reduce<BaseChain[]>(reducer, []),
    };
})();

export const chainLookupTable = {
    all: chains.all.reduce<Dictionary<number>>(lookupTableReducer, {}),
    farmUni: chains.farmUni.reduce<Dictionary<number>>(lookupTableReducer, {}),
    farmiZi: chains.farmiZi.reduce<Dictionary<number>>(lookupTableReducer, {}),
    trade: chains.trade.reduce<Dictionary<number>>(lookupTableReducer, {}),
    veiZi: chains.veiZi.reduce<Dictionary<number>>(lookupTableReducer, {}),
    iPoints: chains.iPoints.reduce<Dictionary<number>>(lookupTableReducer, {}),
    pools: chains.pools.reduce<Dictionary<number>>(lookupTableReducer, {}),
} as any;

export const getChain = (chainId: ChainId) => {
    return chains.all.find((chain) => {
        return chain.id === chainId;
    });
};

export const getTxLink = (tx: string, chain: BaseChain) => {
    let url = chain.scanUrl;
    if (url.substring(url.length - 1) !== '/') {
        url += '/';
    }
    return url + 'tx/' + tx;
};

export const getScanUrlPrefix = (chainId: ChainId | undefined): string => {
    if (chainId === undefined) {
        return '';
    }
    const chain = chains.all.find((chain) => {
        return chain.id === chainId;
    });
    return chain?.scanUrl + 'address/';
};

export const getScanUrl = (chainId: ChainId | undefined): string => {
    if (chainId === undefined) {
        return '';
    }
    const chain = chains.all.find((chain) => {
        return chain.id === chainId;
    });
    return chain?.scanUrl ?? '';
};

const SECONDS_PER_DAY = 24 * 60 * 60;

export const blocksPerDay = (chainId: ChainId) => {
    const chain = getChain(chainId);
    return SECONDS_PER_DAY / (chain?.blockDelta ?? 10);
};

export const chainPriorityFee = {
    [ChainId.BSC]: 0,
    [ChainId.BSCTestnet]: 0,
    [ChainId.EthereumMainnet]: 1.5,
    [ChainId.Arbitrum]: 0.01,
    [ChainId.Matic]: 2.5,
    [ChainId.Aurora]: 0,
    [ChainId.AuroraTestnet]: 0,
    [ChainId.ConfluxESpace]: 0,
} as any;

export default chains;
